.DayPicker-Month {
    margin: 0;
    border-collapse: separate;
    border-spacing: 0.25em;
}

.DayPicker-Caption {
    text-align: center;
}

.DayPicker-Caption > div {
    font-weight: inherit;
    font-size: inherit;
    padding-top: 0.2em;
}

.DayPicker-NavButton {
    top: 0;
    width: 24px;
    height: 24px;
    right: 1em;
    background-size: 100%;
}

.DayPicker-NavButton--prev {
    margin-right: 0;
    left: 1em;
    background-image: url('../images/baseline_keyboard_arrow_left_black_24dp_1x.png');
}

.DayPicker-NavButton--next {
    background-image: url('../images/baseline_keyboard_arrow_right_black_24dp_1x.png');
}

.DayPicker-Day {
    padding: 0;
}

.DayPicker-Day--today {
    color: inherit;
    font-weight: inherit;
    border: 1px solid #f58f84;
    border-radius: 50%;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #f58f84;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #eb442b;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #efeded;
}

@media (-webkit-min-device-pixel-ratio: 2) {
    .DayPicker-NavButton--prev {
        background-image: url('../images/baseline_keyboard_arrow_left_black_24dp_2x.png');
    }

    .DayPicker-NavButton--next {
        background-image: url('../images/baseline_keyboard_arrow_right_black_24dp_2x.png');
    }
}