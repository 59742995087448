.rstcustom__nodeContent {
    width: 100%;
}

.rstcustom__rowTitle {
    display: flex;
    font-weight: normal;
}

.rstcustom__rowLabel:hover {
    box-shadow: none;
}

.rstcustom__rowContents {
    background-color: transparent;
    background: transparent;
    box-shadow: none;
}

.rstcustom__rowContents:hover {
    box-shadow: none;
    transition: none;
    background-color: transparent;
    background: transparent
}

.rstcustom__highlight {
    background-color: transparent;
    background: transparent;
}

.rstcustom__rowWrapper {
    cursor: default
}

.rstcustom__rowWrapper:hover {
    opacity: 1;
    padding: 2px 2px 2px 0
}

.ReactVirtualized__Grid:focus,
.ReactVirtualized__List:focus,
.rst__virtualScrollOverride:focus
{
    outline: none;
}

.ReactVirtualized__Grid__innerScrollContainer {
    left: -44px;
}

.rstcustom__rowLandingPad {
    cursor: grabbing;
}

.rstcustom__expandButton,
.rstcustom__collapseButton {
    top: 35%;
}

.rstcustom__expandButton:before {
    display: inline-block;
    width: 24px; 
    height: 24px;
    content: "";
    background-image: url('../images/baseline_keyboard_arrow_right_black_24dp_1x.png');
    background-size: 24px 24px;
}

.rstcustom__collapseButton:before {
    display: inline-block;
    width: 24px; 
    height: 24px;
    content: "";
    background-image: url('../images/baseline_keyboard_arrow_down_black_24dp_1x.png');
    background-size: 24px 24px;
}

@media (-webkit-min-device-pixel-ratio: 2) {
    .rstcustom__expandButton:before {
        background-image: url('../images/baseline_keyboard_arrow_right_black_24dp_2x.png');
    }
    
    .rstcustom__collapseButton:before {
        background-image: url('../images/baseline_keyboard_arrow_down_black_24dp_2x.png');
    }
}